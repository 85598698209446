<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && popupParam.envWasteVolumeId"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-datepicker
                  :required="true"
                  :editable="editable && !popupParam.envWasteVolumeId"
                  type='month'
                  label="기준월"
                  name="wasteYm"
                  v-model="data.wasteYm"
                />
              </div>
              <div class="col-3">
                <c-plant @input="getEmptyList2" :editable="editable && !popupParam.envWasteVolumeId" :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <c-field
                  :required="true"
                  :editable="editable && !popupParam.envWasteVolumeId"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성부서 & 작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-6">
                <c-text
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="운반횟수"
                  name="wasteTransport"
                  v-model="data.wasteTransport">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  suffix="kg"
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="생산량"
                  name="salesVolume"
                  v-model="data.salesVolume">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  suffix="원"
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="처리단가"
                  name="wasteUnit"
                  v-model="data.wasteUnit">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  suffix="원"
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="처리비용"
                  name="wasteCost"
                  v-model="data.wasteCost">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="폐기물 발생량"
            tableId="processManual"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="grid.data"
            :columnSetting="false"
            :isFullScreen="false"
            :usePaging="false"
            :expandAll="true"
            :hideBottom="true"
            :filtering="false"
            :isExcelDown="false"
          >
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'waste-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envWasteVolumeId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      saveUrl: transactionConfig.env.waste.volume.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      checkUrl: '',
      emptylUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      data: {
        envWasteVolumeId: '',  // 월별 폐기물 일련번호
        plantCd: null,  // 사업장코드
        wasteYear: '',  // 처리년도
        wasteMonth: '',  // 처리월
        wasteTransport: null,  // 폐기물 운반횟수
        salesVolume: null,  // 생산량
        wasteUnit: null,
        wasteCost: null,
        occurs: [], // 폐기물종류별 발생량
      },
      grid: {
        columns: [
          {
            name: 'envWasteMstName',
            field: 'envWasteMstName',
            label: '폐기물종류',
            align: 'center',
            style: 'width:50%',
            sortable: false,
          },
          {
            name: 'occurVolume',
            field: 'occurVolume',
            type: 'number',
            label: '폐기물 발생량(kg)',
            align: 'center',
            style: 'width:50%',
            sortable: false,
          },
        ],
        height: '300px',
        data: [],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.waste.volume.get.url;
      this.emptylUrl = selectConfig.env.waste.volume.empty.url;
      this.checkUrl = selectConfig.env.waste.volume.check.url;
      this.insertUrl = transactionConfig.env.waste.volume.insert.url;
      this.updateUrl = transactionConfig.env.waste.volume.update.url;
      this.deleteUrl = transactionConfig.env.waste.volume.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.envWasteVolumeId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envWasteVolumeId: this.popupParam.envWasteVolumeId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.grid.data = _result.data.occurs;
        },);
      } else {
        this.getEmptyList();
        this.data.wasteYm = this.$comm.getThisMonth();
        this.data.userId = this.$store.getters.user.userId;
      }
    },
    getEmptyList2() {
      this.getEmptyList();
      this.envWasteVolumeId ='';  // 월별 폐기물 일련번호
      this.wasteTransport =null;  // 폐기물 운반횟수
      this.salesVolume =null;  // 생산량
    },
    getEmptyList() {
      this.$http.url = this.emptylUrl;
      this.$http.param = {plantCd: this.data.plantCd}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveInfo() {
      if (this.popupParam.envWasteVolumeId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.checkUrl;
          this.$http.param = {
            plantCd: this.data.plantCd,
            wasteYear: this.data.wasteYm.split('-')[0],
            wasteMonth: this.data.wasteYm.split('-')[1],
          }
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                type: 'info', // success / info / warning / error
                confirmCallback: () => {
                  this.data.wasteYear = this.data.wasteYm.split('-')[0];
                  this.data.wasteMonth = this.data.wasteYm.split('-')[1];
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;
                  
                  let _saveData = this.grid.data.filter( x => {
                    return x.editFlag == 'C' || x.editFlag == 'U'
                  });
                  this.$_.forEach(_saveData, item => {
                    item.envWasteVolumeId = this.data.envWasteVolumeId;
                    item.regUserId = this.$store.getters.user.userId;
                    item.chgUserId = this.$store.getters.user.userId;
                    item.plantCd = this.data.plantCd;
                    item.wasteYear = this.data.wasteYm.split('-')[0];
                    item.wasteMonth = this.data.wasteYm.split('-')[1];
                  });
                  this.data.occurs = _saveData;

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message:
                '동일한 년월['+this.data.wasteYm+']에 데이터가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envWasteVolumeId = result.data.envWasteVolumeId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.envWasteVolumeId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>